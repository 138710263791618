<template>
  <div class="Paiming">
    <div class="banner">
      <div class="container">
      </div>
    </div>
    <div class="cont pd40">
      <div class="container">
        <div class="headTit">
          <h3>The Most Popular <span>Institutions</span></h3>
        </div>
        <div class="_navs">
          <div class="_btns"
               :class="onClass == 0 ? 'on':''"
               @click="onShowPop">
            <i class="iconfont">&#xe6a0;</i>
            The Most Popular School
          </div>
          <div class="_btns"
               :class="onClass == 1 ? 'on':''"
               @click="onShowRank">
            <i class="el-icon-menu"></i>
            School Ranking
          </div>
        </div>
        <div style="position: relative; min-height:300px;">
          <div class="loadingCont"
               v-if="showLoading">
            <Loading class="lii"></Loading>
          </div>
          <transition name="el-fade-in-linear">
            <div v-if="!showLoading">
              <el-row :gutter="24">
                <el-col :span="8"
                        v-for="(item,index) in list"
                        :key="index">
                  <router-link :to="'/school-detail/'+item.id"
                               class="lis">
                    <div class="imgbox">
                      <img :src="item.thumb"
                           alt="">
                    </div>
                    <div class="text">
                      <h3>{{item.en_name}}</h3>
                      <p><i class="iconfont">&#xe62e;</i> <b>QS Ranking: </b><em> {{item.rank == 9999 ? '-' : item.rank}} </em> </p>
                      <p><i class="iconfont">&#xe62e;</i> <b>MOE Ranking: </b><em> {{item.moerank == 9999 ? '-' : item.moerank}} </em> </p>
                      <p><i class="iconfont">&#xe69e;</i> <b>City: </b> {{item.province_name}} {{item.city_name}} </p>
                      <p><i class="iconfont">&#xe655;</i> <b>Major: </b><span v-for="(item2,index) in item.discipline"
                              :key="index">{{item2}}</span></p>
                    </div>
                  </router-link>
                </el-col>
              </el-row>

            </div>
          </transition>
        </div>
        <el-pagination v-if="showpg"
                       background
                       layout="prev, pager, next"
                       @next-click="onNextPage"
                       @prev-click="onPrevPage"
                       @current-change="onCurrentChange"
                       :page-size="pageSize"
                       :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import axiosApi from "../api/api.js";
import { ref } from "vue";
import Loading from '@/components/LoadingBox'

export default {
  components: {
    Loading
  },
  data () {
    return {

    }
  },
  setup () {
    const list = ref()
    const onClass = ref(0)
    const total = ref()
    const pageSize = ref(12)
    const showpg = ref(false)
    const showLoading = ref(true)
    const addPag = () => {
      if (onClass.value == 0) {
        return 'university/popular?page='
      }
      else if (onClass.value == 1) {
        return 'university/popular?rank&page='
      }
    }
    const onCurrentChange = (val) => {
      showLoading.value = true
      const add = addPag()
      axiosApi(
        add + val, {}, 'get'
      ).then((res) => {
        showLoading.value = false
        list.value = res.data.data
        total.value = res.data.total
        pageSize.value = res.data.per_page
      })
    }
    const onNextPage = (val) => {
      showLoading.value = true
      const add = addPag()
      axiosApi(
        add + val, {
      }, 'get'
      ).then((res) => {
        showLoading.value = false
        list.value = res.data.data
        total.value = res.data.total
        pageSize.value = res.data.per_page
      })
    }
    const onPrevPage = (val) => {
      showLoading.value = true
      const add = addPag()
      axiosApi(
        add + val, {
      }, 'get'
      ).then((res) => {
        showLoading.value = false
        list.value = res.data.data
        total.value = res.data.total
        pageSize.value = res.data.per_page
      })
    }

    return {
      onCurrentChange,
      onNextPage,
      onPrevPage,
      list,
      onClass,
      total,
      pageSize,
      showpg,
      showLoading
    }
  },
  beforeMount () {
    axiosApi('university/popular', {}, 'get').then(res => {
      this.showLoading = false
      this.list = res.data.data
      this.showpg = true
      this.total = res.data.total
      this.pageSize = res.data.per_page
    })
  },
  methods: {

    onShowRank () {
      this.showLoading = true
      this.showpg = false
      axiosApi('university/popular?rank', {}, 'get').then(res => {
        this.showLoading = false
        this.showpg = true
        this.list = res.data.data
        this.onClass = 1
        this.curPage = 1
      })
    },
    onShowPop () {
      this.showLoading = true
      this.showpg = false
      axiosApi('university/popular', {}, 'get').then(res => {
        this.showLoading = false
        this.showpg = true
        this.list = res.data.data
        this.onClass = 0
        this.curPage = 1
      })
    },

  }
}
</script>

<style lang="scss" scoped>
.banner {
  background: url(~@/assets/neibanner5.jpg) top center no-repeat;
  height: 400px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 38px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 4px;
    }
  }
}
._navs {
  display: flex;
  margin-bottom: 20px;
  ._btns {
    margin-right: 10px;
    cursor: pointer;
    &:hover,
    &.on {
      color: red;
    }
  }
}
.lis {
  display: block;
  margin-bottom: 20px;
  .imgbox {
    img {
      width: 385px;
      height: 230px;
    }
  }
  .text {
    margin-top: 10px;
    h3 {
      margin-bottom: 10px;
      height: 55px;
    }
    p {
      color: #545454;
      font-size: 14px;
      b {
        font-weight: normal;
        margin-right: 5px;
      }
      em {
        font-style: normal;
        color: red;
        font-weight: bold;
        font-size: 20px;
        margin-left: 5px;
      }
      span {
        display: inline-block;
        border-right: 1px solid #999;
        line-height: 1;
        padding: 0 5px;
        &:last-child {
          border: none;
        }
      }
    }
  }
}
.loadingCont {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  padding-top: 100px;
}
</style>